import React, { useState, useCallback } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import { photos } from "../data/PhotosHome"
import FluidImage from "../components/FluidImage"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      page: wordpressPage(slug: { eq: "home" }) {
        blocks {
          blockName
          innerHTML
          attrs {
            ids
          }
          innerContent
        }
        slug
      }
      images: allWordpressWpMedia {
        edges {
          node {
            id
            wordpress_id
            localFile {
              id
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  `)

  const galleryBlock = data.page.blocks.find(
    b => b.blockName === "core/gallery"
  )
  const imageIds = galleryBlock.attrs.ids

  let photos = []
  let photosFluid = []

  function getById(id, images) {
    const image = images.edges.find(edge => edge.node.wordpress_id == id)
    return image.node
  }

  function getByFilename(filename, images) {
    const image = images.edges.filter(
      edge =>
        edge.node.localFile.childImageSharp.fluid.originalName === filename
    )[0].node
    return image
  }

  function getImageData({ filename, id }) {
    const images = data.images
    if (filename) {
      const image = getByFilename(filename, images)
      return image
    }

    if (id) {
      const image = getById(id, images)
      return image
    }
  }

  imageIds.forEach(id => {
    let photo = getImageData({ id })
    let fluid = photo.localFile.childImageSharp.fluid
    photos.push({
      // src: fluid.src,
      src: fluid.originalImg,
      width: fluid.presentationWidth,
      height: fluid.presentationHeight,
    })
    photosFluid.push(fluid)
  })

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const renderGalleryImage = props => {
    const width = props.photo.width
    const height = props.photo.height
    const fluid = photosFluid.find(p => p.originalImg === props.photo.src)

    const gap = `.5rem`

    return (
      <div onClick={e => openLightbox(e, props)}>
        <Img
          fluid={fluid}
          style={{
            width: `calc(${width}px - ${gap})`,
            marginRight: gap,
            height: `calc(${height}px - ${gap})`,
            marginBottom: gap,
          }}
        />
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Nick Does Food | Photography and Design | Chicago Food Photography" />
      <Gallery
        photos={photos}
        direction="row"
        renderImage={renderGalleryImage}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  )
}

export default IndexPage
